<template lang="pug">
  div.main-content.layout-main__centering.container.pb-5.mt-5
    .login-window
      h3.login-window__title Восстановление пароля
      .login-window-container
        div.errors_container(slot="default")
          div(v-for="(errors) in warnings")
            div(v-for="(error) in errors") {{ error }}
        form(
          v-if="!showConfirmReset"
          @submit.prevent='resetPassword'
          :class="{'was-validated':submitted}"
        )
          .form-group
            label Почта
            input.form-control.reset-email(
              ref='resetEmail',
              v-model.trim='resetEmail',
              v-validate.immediate="'required|email'",
              :class="{'is-invalid': errors.has('resetEmail') && submitted }",
              name='resetEmail',
              type='email',
              required='',
              placeholder="Введите почту"
              @keyup.13='resetPassword'
            )
          button.btn.btn-success.w-100.btn-finex.mt-3(
            type='button',
            @click="resetPassword"
          ) Восстановить пароль
        .base-field__outer(v-if="showConfirmReset")
          .auth_information
            el-alert(
              title="Успешно",
              description="На ваш Email было отправлено письмо с указаниями по восстановлению пароля."
              type="success",
              :closable="false",
              show-icon
            )
            .divider
            el-alert(
              title="",
              :description="`Через ${closeTimer} ${seconds} Вы будете перенаправлены на маркет`",
              type="warning",
              :closable="false",
            )
</template>

<script>
  import utilsMixin from '../components/mixins/utils';
  import {mapGetters} from 'vuex'
  import {RESET_EMAIL} from "../store/actions/auth";

  export default {
    mixins: [utilsMixin],
    data() {
      return {
        showConfirmReset: false,
        submitted: false,
        resetError: '',
        resetEmail: '',
        closeTimer: 5,
        interval: null,
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'loadingRequest',
      ]),
      requestData() {
        let req = this.loadingRequest(RESET_EMAIL);
        return (req && req.response ? req.response : {});
      },
      warnings() {
        if (this.loading(RESET_EMAIL) === 'error') {
          return this.requestData.data ? this.requestData.data.errors : false;
        }
        return false;
      },
      seconds(){
        return this.noun(this.closeTimer, 'секунду', 'секунды', 'секунд')
      },
    },
    methods: {
      resetPassword() {
        this.submitted = true;
        if (!this.errors.has('resetEmail') && this.resetEmail) {
          this.$store.dispatch(RESET_EMAIL, {email: this.resetEmail}).then(res => {
            this.showConfirmReset = true;
            this.interval = setInterval(() => {
              this.closeTimer -= 1;
              if(this.closeTimer === 0){
                this.$emit('closeReset');
                this.$router.push({ name: 'Login' });
                clearInterval(this.interval);
              }
            }, 1000)
          })
        } else {
          this.$refs.resetEmail.focus();
        }
      },
      showResetToggle() {
        this.$emit('showReset');
      }
    },
  }
</script>
<style lang="scss" scoped>
  .reset-email {
    border: 1px solid #ffffff;
  }
</style>
